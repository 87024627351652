import React from 'react';
import { Layout, SEO } from '@componentsShared';
import {RegisterData} from '@components';
import { WrapperRegister } from './styled';

const Register = () => {
    return (
        <Layout>
            <SEO
                title="Gana - Datos personales"
                index='noindex, nofollow'
                slug="/registro/datos-personales"
            />
            <WrapperRegister>
                <RegisterData />
            </WrapperRegister>
        </Layout>
    );
};

export default Register;
